html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*@font-face {
    font-family: 'Neue Lighy';
    src: url('webfont.woff2') format('woff2'), !* Super Modern Browsers *!
         url('webfont.woff') format('woff'), !* Pretty Modern Browsers *!
         url('webfont.ttf')  format('truetype'), !* Safari, Android, iOS *!
         url('webfont.svg#svgFontName') format('svg'); !* Legacy iOS *!
  }*/


a {
    font-family: 'VT323', monospace !important;
    font-weight: 300 !important;
}

/*
#copre {
    font-family: "Neue" !important;
}*/
